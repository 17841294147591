/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906
import * as React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "c4e11f6c59412b97706a7078187c7bb6T1JERVI6Mzc0NTYsRVhQSVJZPTE2NzU5MzUwMDkwMDAsS0VZVkVSU0lPTj0x"
);
ReactDOM.render(<App />, document.getElementById("root"));
