import { Box } from "@mui/material";
import { RaRecord } from "ra-core";
import type { EditProps } from "ra-ui-materialui/src/types";
import { Edit, SimpleForm } from "react-admin";
import { ExcludeProps, MyField } from "./types";
import omit from "lodash/omit";

export interface MyEditProp<RecordType extends RaRecord = any>
  extends EditProps<RecordType> {
  fields: MyField<RecordType>[];
  maxWidth?: number | string;
}

const MyEdit = (props: MyEditProp) => {
  const { fields, maxWidth = "100%", ...rest } = props;
  return (
    <Edit mutationMode="pessimistic" {...rest}>
      <SimpleForm sx={{ maxWidth: maxWidth }}>
        {fields
          .filter((f) => f.isEdit && f.editComponent)
          .map((field: MyField, index) => {
            const {
              editComponent: FieldComponent,
              source,
              label,
              editProps,
              isUpdate,
            } = field;

            if (!FieldComponent) {
              return null;
            }

            const curProps = {
              ...editProps,
            };

            if (FieldComponent === Box) {
              return (
                <FieldComponent
                  key={`${index}`}
                  {...omit(curProps, ExcludeProps)}
                />
              );
            } else {
            }

            return (
              <FieldComponent
                key={`${index}`}
                disabled={!isUpdate}
                source={source}
                label={label}
                {...omit(curProps, ["minWidth", "maxWidth"])}
              />
            );
          })}
      </SimpleForm>
    </Edit>
  );
};

export default MyEdit;
