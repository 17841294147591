import * as React from "react";
import { useState } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import { useRecordContext } from "ra-core";
import { Payments } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button as MuiButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import {
  deduct,
  recharge,
} from "../../service/adminUserWallet/api/adminUserWalletService";

export type Type = "Add" | "Deduct";

export interface AmountButtonProps {
  type: Type;
}

export default function AmountButton({ type }: AmountButtonProps) {
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState("0");
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const onSubmit = async () => {
    if (type === "Add") {
      await recharge({
        userId: record.userId,
        type: record.type,
        amount: number,
        coinSymbol: record.coinSymbol,
      });
    } else {
      await deduct({
        userId: record.userId,
        amount: number,
        type: record.type,
        coinSymbol: record.coinSymbol,
      });
    }

    notify("操作成功");
    handleClose();
  };

  let label = type === "Add" ? "充值" : "扣款";
  return (
    <>
      <Button
        onClick={(e) => {
          setTimeout(() => {
            handleClickOpen();
          });
        }}
        label={label}
      >
        <Payments />
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"xs"}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">{label}</DialogTitle>
          <DialogContent dividers>
            <Box component="form" id={"create"} onSubmit={() => onSubmit()}>
              <Grid container spacing={2}>
                <Grid item flex={1}>
                  <TextField
                    required
                    autoFocus
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    margin="dense"
                    size="medium"
                    id="url"
                    label="请输入金额"
                    type="number"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>关闭</MuiButton>
            <MuiButton form={"create"} type="submit">
              确定
            </MuiButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
