import {
  RaRecord,
  ReferenceFieldClasses,
  ReferenceFieldProps,
} from "react-admin";
import * as React from "react";
import { useCreatePath, useRecordContext } from "ra-core";
import get from "lodash/get";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export type ReferenceToFunctionType = (record: RaRecord) => string;

interface LocalReferenceFieldProps
  extends Omit<ReferenceFieldProps, "reference"> {
  localLabelSource: string;
  reference: string | ReferenceToFunctionType;
}

const stopPropagation = (e: any) => e.stopPropagation();

export function LocalReferenceField(props: LocalReferenceFieldProps) {
  const { localLabelSource, source, reference, link } = props;

  const createPath = useCreatePath();

  const record = useRecordContext(props);
  const id = get(record, source);
  const child = get(record, localLabelSource);

  if (link) {
    const curResource =
      typeof reference === "function" ? reference(record) : reference;

    const resourceLinkPath = createPath({
      resource: curResource,
      id,
      type: typeof link === "function" ? link(record, curResource) : link,
    });
    return (
      <Link
        to={resourceLinkPath as string}
        className={ReferenceFieldClasses.link}
        onClick={stopPropagation}
      >
        {child}
      </Link>
    );
  }
  return (
    <Typography component="span" variant="body2">
      {child}
    </Typography>
  );
}

LocalReferenceField.defaultProps = {
  link: "edit",
};
