import omit from "lodash/omit";
import {
  DateField,
  EditButton,
  maxLength,
  minLength,
  Pagination,
  ReferenceManyField,
  required,
  SelectInput,
  Show,
  ShowButton,
  TabbedShowLayout,
  TextField,
  TextInput,
  TextInputProps,
  WrapperField,
} from "react-admin";
import { UserManagerApi } from "../api/UserManagerApi";
import { EditSelectField } from "../components/EditSelectField";
import MyCreate from "../components/MyCreate";
import MyEdit from "../components/MyEdit";
import { MyList } from "../components/MyList";
import { MyField } from "../components/types";
import { UnLockButton } from "../components/UnLockButton";
import { Admin } from "../model/Admin";
import { BooleanTypeChoices, StatusTypeChoices } from "../model/Core";
import { Field } from "../utils/Field";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import React from "react";
import { MyShowFields } from "../components/MyShowFields";
import { MyExchangeTable } from "../pages/exchange/ExchangeOrder";
import { MyMarginsTable } from "./exchange/ExchangeMargin";
import { UserWalletTable } from "./user/UserWallet";
import { MyNftOrderTable } from "./nft/NftOrder";
import { FundTermUserTable } from "./fund/FundTermUser";
import { FundCurrentUserTable } from "./fund/FundCurrentUser";
import { UserWalletLog } from "./user/UserWalletLog";

function MyTextInput(props: TextInputProps & { localLabelSource: string }) {
  return <TextInput {...omit(props, "localLabelSource")} />;
}

export let fields: MyField<Admin>[];
fields = [
  Field.objectId({
    label: "用户ID",
    isList: false,
  }),
  Field.localRef(
    {
      label: "uid",
      source: "uid",
      isFilter: true,
      isEdit: true,
      isCreate: false,
      isUpdate: false,
      reference: "users",
      referenceLabel: "uid",
      originSource: "id",
      localLabelSource: "uid",
    },
    {
      filterProps: {
        source: "uid",
      },
      editComponent: MyTextInput,
    }
  ),
  Field.text({
    label: "邀请码",
    source: "inviteCode",
    isEdit: true,
    isCreate: false,
    isUpdate: false,
  }),
  Field.editSelect({
    label: "是否代理商",
    source: "isAgent",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否锁定账户",
    source: "isAccountLock",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否水军",
    source: "isMyUser",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否限制币种交易",
    source: "enableSymbolTradeLimit",
    choices: BooleanTypeChoices,
    isFilter: false,
  }),
  Field.refArr({
    label: "限制币种",
    source: "symbolTradeLimitList",
    reference: "coins",
    referenceLabel: "symbol",
    originSource: "symbolTradeLimitList",
    optionValue: "symbol",
    optionText: "symbol",
    isFilter: false,
    isList: false,
  }),
  Field.editAmount({
    label: "最小交易金额(小于等于0则不限制)",
    source: "minTradeAmount",
  }),
  Field.text({
    label: "账号",
    source: "name",
    isEdit: true,
    isCreate: true,
    isUpdate: true,
  }),
  Field.text({
    label: "邮箱",
    source: "email",
    isUpdate: false,
  }),
  Field.text({
    label: "电话",
    source: "phone",
    isUpdate: false,
  }),
  {
    label: "备注",
    source: "desc",
    sortable: false,
    isPrimary: true,
    isFilter: false,
    component: TextField,
    props: {},
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [maxLength(512)],
    },
  },
  {
    label: "密码",
    source: "password",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [minLength(4), maxLength(25)],
    },
  },
  {
    label: "超级密码",
    source: "adminPassword",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [minLength(4), maxLength(25)],
    },
  },
  {
    label: "注册时间",
    sortable: true,
    source: "createdDate",
    component: DateField,
    props: {
      showTime: true,
    },
  },
  {
    label: "状态",
    source: "status",
    component: EditSelectField,
    isFilter: false,
    props: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    filterProps: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: SelectInput,
    editProps: {
      choices: StatusTypeChoices,
      validate: [required()],
    },
  },
  {
    label: "登录锁定",
    source: "lock",
    component: WrapperField,
    isFilter: true,
    props: {
      children: (
        <UnLockButton onClick={async (r: any) => UserManagerApi.unlock(r.id)} />
      ),
    },
  },
  Field.ref({
    label: "电报机器人",
    source: "telBotId",
    reference: "telBotConfigs",
    referenceLabel: "desc",
    originSource: "telBotId",
    isFilter: false,
  }),
];

const resource = {
  show: () => {
    return (
      <Show title={`用户`}>
        <TabbedShowLayout value={0}>
          <TabbedShowLayout.Tab label="用户">
            <MyShowFields fields={fields} />
            <ReferenceManyField
              reference="userWallets"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <UserWalletTable />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="订单" path="orders">
            <ReferenceManyField
              reference="exchangeOrders"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <MyExchangeTable />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="杠杆仓位" path="leverageMargins">
            <ReferenceManyField
              reference="exchangeMarginsLeverage"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <MyMarginsTable marginType={"LEVERAGE"} />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="合约仓位" path="contractMargins">
            <ReferenceManyField
              reference="exchangeMarginsContract"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <MyMarginsTable marginType={"CONTRACT"} />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="nft订单" path="nftOrders">
            <ReferenceManyField
              reference="nftOrders"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <MyNftOrderTable />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="基金持仓" path="fundTermUsers">
            <ReferenceManyField
              reference="fundTermUsers"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <FundTermUserTable />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="活期持仓" path="fundCurrentUsers">
            <ReferenceManyField
              reference="fundCurrentUsers"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <FundCurrentUserTable />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab label="帐变" path="userWalletLog">
            <ReferenceManyField
              reference="userWalletLog"
              target="userId"
              source={"id"}
              label={false}
              pagination={<Pagination />}
            >
              <UserWalletLog />
            </ReferenceManyField>
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </Show>
    );
  },
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[
          <EditButton />,
          <ShowButton />,
          // <AmountButton />,
        ]}
        tableActions={
          <WrapperField>
            {/*<EditButton />*/}
            <UnLockButton
              onClick={async (r: any) => UserManagerApi.unlock(r.id)}
            />
          </WrapperField>
        }
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return (
      <MyEdit
        transform={(data) => {
          const omits = [];
          if (data?.password?.length === 0) {
            omits.push("password");
          }
          if (data?.adminPassword?.length === 0) {
            omits.push("adminPassword");
          }
          return omit(data, omits);
        }}
        fields={fields}
      />
    );
  },
  icon: PermContactCalendarIcon,
};

export default resource;
